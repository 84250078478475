import { createAction } from "redux-actions";

const prefix = "app/";

export const initApp = createAction(prefix + "INIT_APP");

export const setIsInitializedApp = createAction(
  prefix + "SET_IS_INITIALIZED_APP"
);
export const setIsLoadingApp = createAction(prefix + "SET_IS_LOADING_APP");
